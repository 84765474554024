<template>
  <v-card :min-height="150">
    <v-card-text>
      <v-row v-show="statisticsDataLoading">
        <statistics-shimmer
          v-for="item in [0, 1, 2, 3]"
          :key="item"
        />
      </v-row>
      <v-row v-show="!statisticsDataLoading">
        <v-col
          v-for="data in statisticsData"
          :key="data.title"
          cols="12"
          md="3"
        >
          <v-card
            class="d-flex align-center"
            width="95%"
            hover
            ripple
            flat
            :to="data.path ? { path: data.path } : undefined"
            @click="data.href ? onStatisticsClick(data.href) : data.title === 'Revenue' ? showRevenue = !showRevenue : undefined"
          >
            <v-avatar
              size="54"
              :color="resolveStatisticsIconVariation(data.title).color"
              rounded
              class="elevation-1"
            >
              <v-icon
                dark
                color="white"
                size="30"
              >
                {{ resolveStatisticsIconVariation(data.title).icon }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">
                {{ data.title }}
              </p>
              <h3 class="text-xl font-weight-semibold">
                <span v-if="data.title === 'Revenue' && !showRevenue">
                  <v-icon
                    size="30"
                  >
                    {{ icons.mdiEyeOff }}
                  </v-icon>
                </span>
                <span v-else>
                  {{ data.total }}
                </span>
              </h3>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import {
  mdiAccount, mdiAccountPlus, mdiAccountMinus, mdiAccountClock, mdiEyeOff,
} from '@mdi/js'

import StatisticsShimmer from '@/components/partials/shimmers/StatisticsShimmer.vue'

export default {
  components: { StatisticsShimmer },
  data: () => ({
    statisticsData: [],
    statisticsDataLoading: true,
    showRevenue: false,
  }),
  computed: {
    ...mapGetters(['userInfo']),
  },
  setup() {
    const resolveStatisticsIconVariation = data => {
      if (data === 'Total Customers') return { icon: mdiAccount, color: 'primary' }
      if (data === 'New Customers') return { icon: mdiAccountPlus, color: 'success' }
      if (data === 'Customers With Debt') return { icon: mdiAccountMinus, color: 'warning' }
      if (data === 'Unresolved Payments') return { icon: mdiAccountClock, color: 'info' }

      return { icon: mdiAccount, color: 'success' } // default icon and color
    }

    return {
      resolveStatisticsIconVariation,

      // icons
      icons: {
        mdiAccount,
        mdiAccountPlus,
        mdiAccountMinus,
        mdiAccountClock,
        mdiEyeOff,
      },
    }
  },

  mounted() {
    this.getStatistics()
  },
  methods: {
    getStatistics() {
      this.statisticsDataLoading = true
      axios
        .get('analytics/dashboard/index')
        .then(response => {
          this.statisticsData = [
            {
              title: 'Total Customers',
              total: response.data.totalCustomers,
              path: 'customers',
            },
            {
              title: 'New Customers',
              total: response.data.newCustomers,
              path: 'customers?new=true',
            },
            {
              title: 'Customers With Debt',
              total: response.data.customersWithDebt,
              path: 'customers?debt=true',
            },
            {
              title: 'Unresolved Payments',
              total: response.data.unresolvedPayments,
              path: '#unresolved-payments-table',
            },
          ]
          this.statisticsDataLoading = false
        })
        .catch(error => {
          this.statisticsDataLoading = false
          this.$notification.error(error.response.data.message)
        })
    },
    onStatisticsClick(href) {
      this.$vuetify.goTo(href, {
        duration: 500,
        offset: 0,
        easing: 'easeInOutCubic',
      })
    },
  },
}
</script>
