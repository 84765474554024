<template>
  <v-row v-if="hasInvoice">
    <v-col cols="12">
      <v-alert
        border="left"
        type="info"
      >
        <p>{{ invoiceMessage }}</p>
        <v-btn
          color="accent"
          class="ml-2"
          :loading="isLoadingViewInvoice"
          :disabled="isLoadingViewInvoice"
          @click="viewInvoice(latestInvoice.id)"
        >
          <v-icon left>
            {{ icons.view }}
          </v-icon>
          View Invoice
        </v-btn>
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import { mdiEye } from '@mdi/js'

export default {
  data() {
    return {
      hasInvoice: false,
      invoiceMessage: '',
      latestInvoice: {},
      isLoadingViewInvoice: false,
      icons: {
        view: mdiEye,
      },
    }
  },
  mounted() {
    this.fetchLatestInvoice()
  },
  methods: {
    fetchLatestInvoice() {
      axios.get('/latest-service-charge-invoice')
        .then(response => {
          const invoice = response.data
          if (invoice && invoice.status !== 'Paid') {
            this.latestInvoice = invoice
            this.hasInvoice = true
            this.prepareInvoiceMessage()
          } else {
            this.hasInvoice = false
          }
        })
        .catch(error => {
          console.error('Error fetching latest invoice:', error)
        })
    },
    prepareInvoiceMessage() {
      const invoiceDate = new Date(this.latestInvoice.created_at)
      invoiceDate.setMonth(invoiceDate.getMonth() - 1)
      const invoiceMonth = invoiceDate.toLocaleString('default', { month: 'long' })
      const invoiceYear = invoiceDate.getFullYear()

      // Use global filter to format the due date, assuming Vue 2 style for filters
      const dueDate = this.$options.filters.formatDay(new Date(this.latestInvoice.due_date))

      switch (this.latestInvoice.status) {
        case 'Unpaid':
          this.invoiceMessage = `Invoice for ${invoiceMonth} ${invoiceYear} is ready. Please settle by ${dueDate}`
          break
        case 'Overdue':
          this.invoiceMessage = `Alert: ${invoiceMonth} ${invoiceYear} Invoice overdue! Immediate payment required to prevent service interruption.`
          break
        case 'Partially Paid':
          this.invoiceMessage = `Notice: ${invoiceMonth} ${invoiceYear} Invoice partially settled. Complete the outstanding amount by ${dueDate}.`
          break
        default:
          this.hasInvoice = false
      }
    },
    viewInvoice(id) {
      this.isLoadingViewInvoice = true
      axios.get(`/service-charge-invoices/${id}/download`, { responseType: 'blob' })
        .then(response => {
          const pdfBlob = response.data
          const pdfUrl = URL.createObjectURL(pdfBlob)
          window.open(pdfUrl, '_blank')
        })
        .catch(error => {
          console.error('Error viewing invoice:', error)
          this.$toast.error('An error occurred while loading the invoice')
        }).finally(() => {
          this.isLoadingViewInvoice = false
        })
    },
  },
}
</script>
