<template>
  <v-card :height="635">
    <v-card-title>
      <v-col
        cols="12"
        md="10"
      >
        Internet Traffic Details
      </v-col>
    </v-card-title>
    <v-row
      v-if="isLoading"
      align="center"
      justify="center"
      class="fill-height"
    >
      <v-col
        cols="12"
        class="text-center"
      >
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
    </v-row>
    <div
      v-else-if="!isLoading && networkInterfaces.length === 0"
      class="text-center"
    >
      <span>No routers present. Add a router to get its traffic details.</span>
    </div>
    <v-card-text v-else>
      <v-tabs
        v-model="stationTabIndex"
        show-arrows
      >
        <v-tab
          v-for="tab in stations"
          :key="tab.id"
        >
          <span>{{ tab.name }} ROUTER</span>
        </v-tab>
      </v-tabs>
      <v-tabs-items
        v-model="stationTabIndex"
        class="mt-4"
      >
        <v-tab-item
          v-for="tab in stations"
          :key="tab.id"
        >
          <div class="vertical-tabs-container">
            <!-- Conditionally render scroll buttons based on overflow -->
            <v-row>
              <v-col class="col-md-2">
                <div class="d-flex justify-center">
                  <v-btn
                    icon
                    small
                    :disabled="!isScrollUpButtonEnabled"
                    @click="scrollTabsUp"
                  >
                    <v-icon>{{ icons.mdiChevronUp }}</v-icon>
                  </v-btn>
                </div>
                <div
                  ref="verticalTabs"
                  class="scrollable-tabs"
                  :data-station-tab-index="tab.id"
                  style="height: 370px;"
                >
                  <v-tabs
                    v-model="networkInterfaceTabIndex"
                    vertical
                  >
                    <v-tab
                      v-for="(networkInterface, index) in networkInterfaces"
                      :key="`${tab.id}-${index}`"
                    >
                      {{ networkInterface.name }}
                    </v-tab>
                  </v-tabs>
                </div>
                <div class="d-flex justify-center">
                  <v-btn
                    icon
                    small
                    class="mt-6"
                    :disabled="!isScrollUpButtonEnabled"
                    @click="scrollTabsDown"
                  >
                    <v-icon>{{ icons.mdiChevronDown }}</v-icon>
                  </v-btn>
                </div>
              </v-col>
              <v-col class="col-md-10">
                <div class="graph-container">
                  <transition name="fade">
                    <internet-traffic-details-graph
                      :key="`station-${stationTabIndex}-interface-${networkInterfaceTabIndex}`"
                      :height="370"
                      :url="url"
                      :interface-name="currentInterfaceName"
                    />
                  </transition>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios'
import { mdiChevronUp, mdiChevronDown } from '@mdi/js'
import InternetTrafficDetailsGraph from '@/components/partials/InternetTrafficDetailsGraph.vue'

export default {
  name: 'InternetTrafficDetailsCard',
  components: {
    InternetTrafficDetailsGraph,
  },
  data() {
    return {
      isLoading: true,
      isScrollUpButtonEnabled: true,
      isScrollDownButtonEnabled: false,
      stations: [],
      stationTabIndex: 0,
      networkInterfaceTabIndex: 0,
      networkInterfaces: [],
      icons: { mdiChevronUp, mdiChevronDown },
    }
  },
  computed: {
    currentStation() {
      return this.stations[this.stationTabIndex] || null
    },
    url() {
      return this.currentStation ? `router/station-traffic-data/${this.currentStation.id}?interfaceName=${this.currentInterfaceName}` : null
    },
    currentInterfaceName() {
      const networkInterface = this.networkInterfaces[this.networkInterfaceTabIndex]

      return networkInterface ? networkInterface.name : null
    },
  },
  watch: {
    stationTabIndex(newIndex, oldIndex) {
      if (newIndex !== oldIndex) {
        this.updateNetworkInterfaces()
      }
    },
    networkInterfaces() {
      if (this.networkInterfaces.length > 8) {
        this.isScrollDownButtonEnabled = true
      } else {
        this.isScrollDownButtonEnabled = false
        this.isScrollUpButtonEnabled = false
      }
    },
  },
  mounted() {
    this.getStations()
  },
  methods: {
    getStations() {
      axios.get('stations?perPage=all').then(response => {
        const stations = response.data
        this.stations = stations

        // Automatically load network interfaces for the first station if exists
        if (stations.length > 0) {
          this.stationTabIndex = 0
          this.getNetworkInterfaces(stations[0].id)
        }
      }).catch(error => {
        console.error('Error fetching stations:', error)

        // Handle error appropriately
      })
    },
    updateNetworkInterfaces() {
      const stationId = this.stations[this.stationTabIndex]?.id
      if (stationId) {
        this.getNetworkInterfaces(stationId)
      }
    },
    getNetworkInterfaces(stationId) {
      axios.get(`network-interfaces/${stationId}`).then(response => {
        this.networkInterfaces = response.data
      }).catch(error => {
        console.error(`Error fetching network interfaces for station ${stationId}:`, error)

        // Handle error appropriately
      }).finally(() => {
        this.isLoading = false
      })
    },
    scrollTabsUp() {
    // Find the .scrollable-tabs container for the active station tab
      const activeTabContainer = document.querySelector(`.scrollable-tabs[data-station-tab-index="${this.stations[this.stationTabIndex].id}"]`)
      if (activeTabContainer) {
        activeTabContainer.scrollTop -= 100
      }
    },

    scrollTabsDown() {
    // Find the .scrollable-tabs container for the active station tab
      const activeTabContainer = document.querySelector(`.scrollable-tabs[data-station-tab-index="${this.stations[this.stationTabIndex].id}"]`)
      if (activeTabContainer) {
        activeTabContainer.scrollTop += 100
      }
    },
  },
}
</script>

<style>
.graph-container {
  position: relative;
  min-height: 400px; /* Adjust based on the expected height of the graph */
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
.scrollable-tabs {
  overflow-y: auto;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none;  /* For Internet Explorer and Edge */
  scroll-behavior: smooth;
}

.scrollable-tabs::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}
</style>
