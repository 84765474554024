<template>
  <v-row v-if="hasOfflineRouters">
    <v-col cols="12">
      <v-alert
        border="left"
        type="info"
      >
        <p v-if="offlineRouters.length === 1">
          The router at {{ offlineRouters[0].station.name }} ({{ offlineRouters[0].host }}) is offline.
          Please investigate and take necessary actions to restore the connection.
        </p>
        <p v-else>
          The following routers are currently offline. Please investigate and take necessary actions to restore the connections:
        </p>
        <ul v-if="offlineRouters.length > 1">
          <li
            v-for="router in offlineRouters"
            :key="router.id"
          >
            {{ router.station.name }} ({{ router.host }})
          </li>
        </ul>
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      hasOfflineRouters: false,
      offlineRouters: [],
    }
  },
  mounted() {
    this.fetchOfflineRouters()
  },
  methods: {
    fetchOfflineRouters() {
      axios.get('/routers/offline')
        .then(response => {
          this.offlineRouters = response.data
          this.hasOfflineRouters = this.offlineRouters.length > 0
        })
        .catch(error => {
          console.error('Error fetching offline routers:', error)
        })
    },
  },
}
</script>
