<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h2>
          {{ greeting }}, {{ userInfo.name }}
        </h2>
      </v-col>
      <v-col cols="12">
        <offline-routers-alert />
        <service-charge-invoice-alert />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <dashboard-statistics-card></dashboard-statistics-card>
      </v-col>
      <v-col
        v-if="appEnvironment === 'demo'"
        cols="12"
      >
        <v-alert
          border="left"
          type="info"
        >
          <h3 class="white--text">
            Welcome to Dashernet Demo.
          </h3>
          <p>Please note that all data shown in this demo version are simulated and for demonstration purposes only.</p>
          <p>Some features such as sending SMS are disabled in this demo version.</p>
          <p>All demo data are refreshed  every 48 hours</p>
        </v-alert>
      </v-col>
      <v-col
        cols="12"
        md="12"
      >
        <unresolved-payments :items-per-page="5" />
      </v-col>
      <v-col
        class="mt-8"
        cols="12"
        md="12"
      >
        <internet-traffic-details />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import DashboardStatisticsCard from './DashboardStatisticsCard.vue'
import UnresolvedPayments from '@/components/tables/UnresolvedPaymentsTable.vue'
import InternetTrafficDetails from './InternetTrafficDetailsCard.vue'
import ServiceChargeInvoiceAlert from '@/components/partials/ServiceChargeInvoiceAlert.vue'
import OfflineRoutersAlert from '@/components/partials/OfflineRoutersAlert.vue'

export default {
  components: {
    DashboardStatisticsCard,
    UnresolvedPayments,
    InternetTrafficDetails,
    ServiceChargeInvoiceAlert,
    OfflineRoutersAlert,
  },
  data() {
    return {
      appEnvironment: process.env.VUE_APP_ENVIRONMENT,
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    greeting() {
      const currentHour = new Date().getHours()
      if (currentHour < 12) {
        return 'Good Morning'
      }
      if (currentHour < 18) {
        return 'Good Afternoon'
      }

      return 'Good Evening'
    },
  },
}
</script>
